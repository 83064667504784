import React, { useEffect, useRef, useState } from "react";
import backgroundImage from "../../assets/background1.jpg";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import { notification } from "../../const/notification";

const details = navigator.userAgent;
const regExp = /android|iphone/i;
const isMobileDevice = regExp.test(details);

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  console.log(
    mediaWidth,
    mediaHeight,
    aspect,
    "mediaWidth, mediaHeight, aspect"
  );
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: isMobileDevice ? 45 : 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Post = () => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const myCanvas = useRef();
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(1 / 1);
  const [cropModal, setCropModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [name, setName] = useState("");

  useEffect(() => {
    const context = myCanvas.current.getContext("2d");
    var sources = {
      image2: backgroundImage,
    };
    loadImages(sources, function (images) {
      let arr = name?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const name1 = arr.join(" ");

      context.drawImage(images.image2, 0, 0, 1090, 1600);

      context.font = "700 42px sans-serif";
      context.fillStyle = "#ffffff";
      context.textAlign = "left";
      wrapText(context, name1, 450, 1270, 650, 50);
    });
  }, []);

  function wrapText(context, text, x, y, maxWidth, lineHeight) {
    var words = text.split(" ");
    var line = "";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  function onSelectFile(e) {
    setCropModal(true);
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      console.log("width, height", width, height);
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function handleChange() {
    setCropModal(!cropModal);
    const context = myCanvas.current.getContext("2d");
    setImagePreview(previewCanvasRef.current.toDataURL("image/png"));
    var sources = {
      image1: previewCanvasRef.current.toDataURL("image/png"),
      image2: backgroundImage,
    };
    loadImages(sources, function (images) {
      let arr = name?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const name1 = arr.join(" ");

      context.drawImage(images.image2, 0, 0, 1090, 1600);
      context.save();
      context.beginPath();
      context.strokeStyle = "#ffffff";
      context.stroke();
      context.drawImage(images.image1, 72, 1209.5, 338, 338);
      context.restore();
      context.font = "700 42px sans-serif";
      context.fillStyle = "#ffffff";
      context.textAlign = "left";
      wrapText(context, name1, 450, 1270, 650, 50);
    });
  }

  function onNameChange(e) {
    let arr = e.target.value.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const name1 = arr.join(" ");
    const context = myCanvas.current.getContext("2d");
    if (imagePreview) {
      var sources = {
        image1: imagePreview,
        image2: backgroundImage,
      };

      loadImages(sources, function (images) {
        context.drawImage(images.image2, 0, 0, 1090, 1600);
        context.save();
        context.beginPath();
        context.strokeStyle = "#ffffff";
        context.stroke();
        context.drawImage(images.image1, 72, 1209.5, 338, 338);
        context.restore();
        context.font = "700 42px sans-serif";
        context.textAlign = "left";
        wrapText(context, name1, 450, 1270, 650, 50);
      });
    } else {
      var sources = {
        image2: backgroundImage,
      };
      loadImages(sources, function (images) {
        context.drawImage(images.image2, 0, 0, 1090, 1600);
        context.font = "700 42px sans-serif";
        context.fillStyle = "#ffffff";
        context.textAlign = "left";
        wrapText(context, name1, 450, 1270, 650, 50);
      });
    }
  }

  function loadImages(sources, callback) {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for (var src in sources) {
      numImages++;
    }
    for (var src in sources) {
      let width;
      let height;
      images[src] = new Image();

      images[src].crossOrigin = "anonymous";
      images[src].onload = function () {
        if (++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const DownloadCanvasAsImage = async (data) => {
    if (!imagePreview) {
      notification({
        type: "error",
        message: "Please Upload Image",
      });
    } else {
      let downloadLink = document.createElement("a");
      let canvas = myCanvas.current;
      let url;
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        downloadLink.setAttribute("download", "ISupportLVBBizExpo.jpeg");
        let dataURL = canvas.toDataURL("image/jpeg");
        url = dataURL.replace(
          /^data:image\/jpeg/,
          "data:application/octet-stream"
        );
      } else {
        downloadLink.setAttribute("download", "ISupportLVBBizExpo.png");
        let dataURL = canvas.toDataURL("image/png");
        url = dataURL.replace(
          /^data:image\/png/,
          "data:application/octet-stream"
        );
      }
      downloadLink.setAttribute("href", url);
      downloadLink.click();
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        console.log(
          "###",
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        );
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div className="card m-1 p-2" style={{ maxWidth: "700px" }}>
      <div className="row d-flex align-items-end">
        <div className="col-sm-6 mb-2">
          <label for="name" className="form-label">
            નામ
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="name"
            placeholder="તમારું નામ"
            value={name}
            onChange={(e) => {
              onNameChange(e);
              setName(e?.target?.value);
            }}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <label for="inputFile" className="form-label">
            તમારો ફોટો મુકો
          </label>
          <input
            className="form-control form-control-sm"
            id="inputFile"
            type="file"
            name="fileInput"
            accept="image/*"
            onChange={onSelectFile}
          />
        </div>
        <div className="col-sm-2 mb-2 text-center">
          <Button
            type="submit"
            className="btn btn-sm btn-warning"
            // disabled={!imagePreview}
            onClick={DownloadCanvasAsImage}
          >
            Download
          </Button>
        </div>
      </div>
      <div>
        <canvas
          ref={myCanvas}
          width={1090}
          height={1600}
          style={{
            // border: "2px solid",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
            width: "100%",
          }}
        />
      </div>

      <Modal show={cropModal}>
        <Modal.Header style={{ display: "block" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "5px",
            }}
          >
            <div>Set your Image</div>
            {/* <Button variant="primary" onClick={handleChange}>
              Save Changes
            </Button> */}
            <Button className="btn btn-sm btn-warning" onClick={handleChange}>
              Save Changes
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!!imgSrc && (
            <ReactCrop
              circularCrop={true}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              // locked
              // style={{ width: "100%" }}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <div>
            {/* {!!completedCrop && ( */}
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
            {/* )} */}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleChange}>
            Close
          </Button>
          <Button variant="primary" onClick={handleChange}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Post;
